import Q from 'q';
import _ from 'underscore';
import to_boolean from 'nllib/src/convert/to_boolean';
import wait_util from 'nllib/src/common/wait_util';

export default {
    components: {},
    data() {
        return {
            entity_list: [],

            loading: true,
        };
    },
    methods: {
        __init_data() {
            const __this = this;
            const __init_entity_list = __this.__init_entity_list;

            __this.loading = true;

            return Q.when()
                .then(function () {
                    return wait_util({
                        check() {
                            const dom_list = document.querySelectorAll(`.skeleton-render-a-lot-of-dom .el-skeleton__item`);
                            if (dom_list.length <= 0) return {flag: false};
                            return {flag: true};
                        },
                    });
                })
                .then(function () {
                    return __init_entity_list();
                })
                .then(function () {
                    return wait_util({
                        check() {
                            const dom_list = document.querySelectorAll(`.button-container button`);
                            if (dom_list.length <= 0) return {flag: false};
                            return {flag: true};
                        },
                    });
                })
                .then(function () {
                    __this.loading = false;
                })
                .catch(function (ex) {
                    if (!ex) return Q.reject(ex);
                    const enable_log_error = !ex.disable_log_error;
                    if (!enable_log_error) return Q.reject(ex);
                    console.error(ex);
                    console.trace();
                    if (ex.stack) console.error(ex.stack);
                    return Q.reject(ex);
                })
                ;
        },

        __init_entity_list() {
            const __this = this;

            const entity_list = [];

            for (let i = 0; i < 9999; i++) {
                const entity = {
                    id: i,
                };
                entity_list.push(entity);
            }

            __this.entity_list = entity_list;
        },
    },
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;

        // 感觉设置了meta.enable_keep_alive = false时，
        // 在第二次打开同一页面，效果会好一点（F5的话，就没有区别）
        // 不确定是否错觉
        const __init_data = __this.__init_data;
        __init_data();
    },
    activated() {
        const __this = this;
        // const __init_data = __this.__init_data;
        // __init_data();
    },
    deactivated() {
        // const __this = this;
    },
}
